// ... remove direct imports except for React Router, ROUTES, and Guards ...
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ROUTES from './Routes';
import Modals from '../pages/modals/Modals';
import AuthGuard from '@/features/auth/components/AuthGuard';
import RegisteredGuard from '@/features/auth/components/RegisteredGuard';
import KycGuard from '@/features/auth/components/KycGuard';
import { lazy, Suspense } from 'react';
import { GoogleAnalytics } from '@/components/GoogleAnalytics';

// Replace with lazy imports
const Lobby = lazy(() => import('../pages/lobby/Lobby'));
const Table = lazy(() => import('../pages/table/TablePage'));
const Friends = lazy(() => import('../pages/friends/Friends'));
const Register = lazy(() => import('../pages/register/Register'));
const MyHub = lazy(() => import('../pages/MyHub/MyHub'));
const Auth = lazy(() => import('../pages/auth/Auth'));
const KycSumsub = lazy(() => import('../pages/kyc/KycSumsub'));
const Replay = lazy(() => import('@/pages/replay/Replay'));
const FriendsRequest = lazy(() => import('../pages/friendsRequest/FriendsRequest'));
const Avaturn = lazy(() => import('../pages/avaturn/Avaturn'));
const ProductBoardPortal = lazy(() => import('../pages/ProductBoardPortal/ProductBoardPortal'));
const AgentDashboard = lazy(() => import('@/pages/agentDashboard/AgentDashboard'));
const HandHistory = lazy(() => import('@/pages/handHistory/HandHistory'));

export const AppRouter = () => {
  /**
   * Consider different auth guard levels:
   * - PrivyAuthentication -> if not Navigates to Login
   * - UserRegistration -> if not Navigates to Register
   * - KYCComplete (Deepest Level Auth) -> if not Navigates to KYC
   */

  return (
    <Router>
      <GoogleAnalytics />
      <Modals />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route element={<AuthGuard />}>
            <Route element={<RegisteredGuard />}>
              <Route element={<KycGuard />}>
                <Route path={ROUTES.TABLE} element={<Table />} />
                <Route path={ROUTES.REPLAY} element={<Replay />} />
                <Route path={ROUTES.HAND_HISTORY} element={<HandHistory />} />
                <Route path={ROUTES.LOBBY} element={<Lobby />} />
                <Route path={ROUTES.FRIENDS} element={<Friends />} />
                <Route path={ROUTES.FRIENDS_REQUEST} element={<FriendsRequest />} />
                <Route path={ROUTES.MY_HUB} element={<MyHub />} />
                <Route path={ROUTES.DEFAULT} element={<Lobby />} />
                <Route path={ROUTES.AVATURN} element={<Avaturn />} />
                <Route path={ROUTES.PRODUCT_BOARD} element={<ProductBoardPortal />} />
                <Route path={ROUTES.AGENT_DASHBOARD} element={<AgentDashboard />} />
              </Route>
              <Route path={ROUTES.KYC} element={<KycSumsub />} />
            </Route>
            <Route path={ROUTES.REGISTER} element={<Register />} />
          </Route>
          <Route path={ROUTES.LOGIN} element={<Auth />} />
        </Routes>
      </Suspense>
    </Router>
  );
};
