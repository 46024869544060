import ROUTES from '@/routers/Routes';
import { usePrivy } from '@privy-io/react-auth';
import { useDispatch } from 'react-redux';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { AuthEvents, authActionCreator } from '../../../services/auth/authActions';
import SplashScreen from './SplashScreen/SplashScreen';
import LogoLoadingSpinner from '@/components/LogoLoadingSpinner/LogoLoadingSpinner';
import { useEffect } from 'react';

const AuthGuard = () => {
  const { ready, authenticated } = usePrivy();
  const location = useLocation();
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(location.search);
  const referralCode = searchParams.get('referral') || searchParams.get('r');

  useEffect(() => {
    if (referralCode) {
      sessionStorage.setItem('referralCode', referralCode);
    }
  }, [referralCode]);

  if (!ready) {
    return (
      <SplashScreen logoHeader={false}>
        <LogoLoadingSpinner size="23" />
      </SplashScreen>
    );
  }

  if (!authenticated) {
    return <Navigate to={ROUTES.LOGIN} state={{ redirectTo: location }} />;
  }

  dispatch(authActionCreator(AuthEvents.FETCH_AUTHENTICATED_USER_DATA));

  return authenticated ? <Outlet /> : <Navigate to={ROUTES.LOGIN} />;
};

export default AuthGuard;
