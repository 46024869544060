import ROUTES from '@/routers/Routes';
import { ReducerState } from '@/services/common/lib/reducerState';
import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';
import SplashScreen from './SplashScreen/SplashScreen';
import KycApproved from '../../../pages/kyc/components/KycApproved';
import Modal from '@/components/Modal/Modal';
import LogoLoadingSpinner from '@/components/LogoLoadingSpinner/LogoLoadingSpinner';

interface KycGuardProps {
  skip?: boolean;
}

const KycGuard = ({ skip }: KycGuardProps) => {
  const isLoading = useSelector((state: ReducerState) => !!state.auth.loading);
  const approvedKyc = useSelector((state: ReducerState) => !!state.auth.kyc?.approved);
  const hasAcceptedGreeting = useSelector((state: ReducerState) => !!state.auth.hasAcceptedGreeting);
  const userId = useSelector((state: ReducerState) => state.auth.id);

  if (isLoading && !approvedKyc) {
    return (
      <SplashScreen logoHeader={false}>
        <LogoLoadingSpinner size="23" />
      </SplashScreen>
    );
  }

  if (userId && approvedKyc && hasAcceptedGreeting === false) {
    return (
      <SplashScreen logoHeader>
        <Modal open={true}>
          <KycApproved userId={userId} />
        </Modal>
      </SplashScreen>
    );
  }

  return skip || approvedKyc ? <Outlet /> : <Navigate to={ROUTES.KYC} />;
};

export default KycGuard;
